import React from 'react';
import { Link } from 'react-router-dom';
import { WebSiteData } from '../Data/WebSiteData';
import { Banner } from '../Components/Banner';
import {Helmet} from "react-helmet";

export const Contact = () => {
    return (
      <>
      <Helmet>
          <title>Contact Coding Nectar | Leading Website Design & SEO in Gurgaon</title>
          <meta name="title" content="Contact Coding Nectar | Leading Website Design & SEO in Gurgaon" />
          <meta name="description" content="Get in touch with Coding Nectar for top-notch website design and SEO services in Gurgaon. Fill out our contact form or call us to start your project today!" />
          <meta name="keywords" content="Leading Website Design & SEO in Gurgaon" />

          <meta property="og:site_name" content="Coding Nectar" />
          <meta property="og:url" content="https://www.codingnectar.com/contact" />
          <meta property="og:title" content="Contact Coding Nectar | Leading Website Design & SEO in Gurgaon" />
          <meta property="og:type" content="website" />
          <meta property="og:description" content="Get in touch with Coding Nectar for top-notch website design and SEO services in Gurgaon. Fill out our contact form or call us to start your project today!" />
          <meta property="og:image" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
          <meta property="og:image:secure_url" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="628" />
          <meta name="twitter:site" content="@codingnectar" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Contact Coding Nectar | Leading Website Design & SEO in Gurgaon" />
          <meta name="twitter:description" content="Get in touch with Coding Nectar for top-notch website design and SEO services in Gurgaon. Fill out our contact form or call us to start your project today!" />
          <link rel="canonical" href="https://www.codingnectar.com/contact" />
      </Helmet>
      <Banner className={'contact-banner'} heading={'Contact Us'} subHeading={'Contact Us'} img={'contactnew.png'} imgTag={'Alt title here'}/>  
      <section>
        <div className="contact-secA">
          <div className="container">
            <ul className="flex">
                {WebSiteData?.contact?.slice(0,4).map((data, index) => {
                        const { heading,icon,items } = data;
                        return (
                            <li key={index}>
                              <div className="ico">{icon}</div>
                              <h6>{heading}</h6>
                              {items && items.map((link,linkindex) => {
                                const { title,url } = link;
                                return(
                                  <Link to={url} key={linkindex}>{title}</Link>
                                )
                              })}
                            </li>
                        );
                    })}
            </ul>
            <div className="mapSec">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13941.948694334413!2d75.71091686767579!3d29.1208178!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9f0bc9ffc299639%3A0xe929b548e59db611!2sCoding%20Nectar!5e0!3m2!1sen!2sin!4v1732287713646!5m2!1sen!2sin" width="100%" height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </section>
      
      </>
    );
  };