import React,{useState} from 'react';
import {Helmet} from "react-helmet";
import { WebSiteData } from '../Data/WebSiteData';
import { Banner } from '../Components/Banner';
import { PortfolioPop } from '../Components/PortfolioPop';
import { useMaster } from '../Components/MasterPage'
export const Portfolio = () => { 
   const {showPop,model,setModel} = useMaster();
    return (
      <>
      <Helmet>
          <title>Explore Our Portfolio | Coding Nectar - Top Website Design in Gurgaon</title>
          <meta name="title" content="Explore Our Portfolio | Coding Nectar - Top Website Design in Gurgaon" />
          <meta name="description" content="Discover the diverse projects in our portfolio. Coding Nectar showcases our best website designs and development work in Gurgaon. See our success stories!" />
          <meta name="keywords" content="Top Website Design in Gurgaon" />
          <meta property="og:site_name" content="Coding Nectar" />
          <meta property="og:url" content="https://www.codingnectar.com/portfolio" />
          <meta property="og:title" content="Explore Our Portfolio | Coding Nectar - Top Website Design in Gurgaon" />
          <meta property="og:type" content="website" />
          <meta property="og:description" content="Discover the diverse projects in our portfolio. Coding Nectar showcases our best website designs and development work in Gurgaon. See our success stories!" />
          <meta property="og:image" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
          <meta property="og:image:secure_url" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="628" />
          <meta name="twitter:site" content="@codingnectar" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Explore Our Portfolio | Coding Nectar - Top Website Design in Gurgaon" />
          <meta name="twitter:description" content="Discover the diverse projects in our portfolio. Coding Nectar showcases our best website designs and development work in Gurgaon. See our success stories!" />
          <link rel="canonical" href="https://www.codingnectar.com/portfolio" />
        </Helmet>
        <Banner className={'portfolio-banner'} heading={'Portfolio'} subHeading={'Portfolio'} img={'portfolionew.png'} imgTag={'Digital Marketing Services'}/>  
        <div className="porfolio-secA bg-back">
          <div className="container">
            <div className="grid">
              {WebSiteData?.portfolio?.map((data,index) => {
                const {name,img,mobile,imgTag,imgWebsite,url,clientLogo,width} = data
                return(
                  <div className="col" key={index}>
                    <div className='port-blair item-md'>
                      <figure>{img && <img src={require(`../assets/images/portfolio/${img}`)} alt={imgTag}/>}</figure>
                      <figcaption>
                        <h5>{name}</h5>
                        <button className="btn" type='button' onClick={() => {showPop('portfolio-pop');setModel(prevModel => ({ ...prevModel, img: imgWebsite,url:url, mobile: mobile }))}}>Website View</button>
                      </figcaption>
                    </div>
                    <div className='logo_showcase'>
                        <figure>{img && <img src={require(`../assets/images/portfolio/${clientLogo}`)} alt={imgTag} width={width} />}</figure>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <PortfolioPop model={model} setModel={setModel} />
      </>
    );
  };